import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import { useForm } from "@formspree/react";
import BeatLoader from "react-spinners/BeatLoader";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

export const SignupForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  props,
}) => {
  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Welcome. We exist to make student life easier.",
  };
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [state, handleSubmit] = useForm("xvoyqzwv", {
    data: {
      subject: "Qno Signup",
      email: () => {
        return email;
      },
      name: () => {
        return name;
      },
    },
  });
  const { submitting, succeeded } = state;

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            tag="h1"
            data={sectionHeader}
            className="center-content"
          />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        label="Full name"
                        placeholder="Full name"
                        labelHidden
                        required
                        onBlur={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="Email"
                        onBlur={(e) => {
                          setEmail(e.target.value);
                        }}
                        labelHidden
                        required
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="password"
                        label="Password"
                        placeholder="Password"
                        labelHidden
                        required
                      />
                    </div>
                    <div className="mt-24 mb-32">
                      <Button color="primary" wide>
                        {!submitting && "Sign up"}
                        {submitting && (
                          <BeatLoader
                            loading={true}
                            color={"white"}
                            size={10}
                            margin={5}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        )}
                        {!submitting && succeeded && "Complete"}
                      </Button>
                    </div>
                  </fieldset>
                </form>
                <div className="signin-bottom has-top-divider">
                  <div className="pt-32 text-xs center-content text-color-low">
                    Already have an account?{" "}
                    <Link to="/login/" className="func-link">
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;

export default SignupForm;
