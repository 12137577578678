import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "A platform for students to learn better, faster and easier",
      paragraph:
        "A one-of-a-kind platform that provides direct access to industry experts from top companies, seeking to help students gain relevant, real-world experience and insight while they earn credits with their faculty advisor.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
              data-reveal-delay="150"
            />
            <div
              className="container-sm reveal-from-bottom"
              data-reveal-delay="300"
              data-reveal-offset="0"
              style={placeholderStyle}
            >
              <div className="illustration-element-02">
                <Image
                  className="has-shadow"
                  src={require("./../../assets/images/features-placeholder.png")}
                  alt="Features placeholder"
                  width={896}
                  height={502}
                />
              </div>
            </div>
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={
                          require("./../../assets/images/feature-tile-icon-01.svg")
                            .default
                        }
                        alt="Features tile icon 01"
                        width={56}
                        height={56}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Student Driven</h4>
                    <p className="m-0 text-sm">
                      Qno is the place for students to talk about projects and
                      courses. Students will find projects, lessons, questions
                      and classwork on a simple platform that helps them succeed
                      at school
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="150"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={
                          require("./../../assets/images/feature-tile-icon-02.svg")
                            .default
                        }
                        alt="Features tile icon 02"
                        width={56}
                        height={56}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Professional Guidence</h4>
                    <p className="m-0 text-sm">
                      Fresh out of the box: an academic adviser who also works
                      in your field of interest. Our advisers bring enthusiasm,
                      fresh ideas and real-world experience to every meeting.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="300"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={
                          require("./../../assets/images/feature-tile-icon-03.svg")
                            .default
                        }
                        alt="Features tile icon 03"
                        width={56}
                        height={56}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Self Paced</h4>
                    <p className="m-0 text-sm">
                      Designed by students, we know what it is like trying to
                      meet deadlines with a busy schedule. That is why our
                      entire platform is self-paced. You have complete control
                      over your projects.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="450"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={
                          require("./../../assets/images/feature-tile-icon-04.svg")
                            .default
                        }
                        alt="Features tile icon 04"
                        width={56}
                        height={56}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Get Rewarded</h4>
                    <p className="m-0 text-sm">
                      When students want to work on something, they go to Qno.
                      Once their project gets the required number of votes, they
                      can work on it and keep the money they earn by setting a
                      deadline for when they complete their project.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="600"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={
                          require("./../../assets/images/feature-tile-icon-05.svg")
                            .default
                        }
                        alt="Features tile icon 05"
                        width={56}
                        height={56}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Online Platform</h4>
                    <p className="m-0 text-sm">
                      You can work on projects with other students halfway
                      around the globe, work with virtual mentors, and connect
                      virtually to industry experts.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="750"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={
                          require("./../../assets/images/feature-tile-icon-06.svg")
                            .default
                        }
                        alt="Features tile icon 06"
                        width={56}
                        height={56}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Networking</h4>
                    <p className="m-0 text-sm">
                      At the end of a project, you've not only completed a
                      real-world example of your current course work, been
                      renumerated for your efforts, but also add a valuable
                      connect to your network for when you graduate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const placeholderStyle = {
  paddingBottom: "80px",
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
