import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Testimonial extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Loved by students and professionals worldwide",
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <Image
                      src={require("./../../assets/images/stars-5.svg").default}
                      alt="Rating"
                      width={96}
                      height={16}
                    />
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      The Qno platform has been an incredible asset to my
                      business. I have used it to find a student who has now
                      become one of my best employees. I have also been able to
                      get projects done quickly and efficiently, which helps me
                      start my business, and I am giving back to the community
                      by helping students get the experience they need.
                    </p>
                  </div>
                  <div className="testimonial-item-footer pt-24">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-01.jpg")}
                        alt="Testimonial 01"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="testimonial-item-name text-xs fw-500 text-color-high">
                      Ken Kinson
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="150"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <Image
                      src={require("./../../assets/images/stars-5.svg").default}
                      alt="Rating"
                      width={96}
                      height={16}
                    />
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      Qno is a great way to find work, particularly for students
                      and graduates who are just entering the workplace. I was
                      able to learn so much from working on real-world projects
                      and the excellent mentorship available through the
                      program.
                    </p>
                  </div>
                  <div className="testimonial-item-footer pt-24">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-02.jpg")}
                        alt="Testimonial 02"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="testimonial-item-name text-xs fw-500 text-color-high">
                      Jeffrey Harris
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="300"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <Image
                      src={require("./../../assets/images/stars-5.svg").default}
                      alt="Rating"
                      width={96}
                      height={16}
                    />
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      I was given the opportunity to use Qno in University and
                      found it an invaluable tool for getting my ideas out of my
                      head and into a real project. Using Qno I could start
                      projects with other students, making truly collaborative
                      work, or in many cases work alone. The platform is simple
                      to use, easy to share and can be used by anyone regardless
                      of experience.
                    </p>
                  </div>
                  <div className="testimonial-item-footer pt-24">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-03.jpg")}
                        alt="Testimonial 03"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="testimonial-item-name text-xs fw-500 text-color-high">
                      Emilia Jovic
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
