import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "See how students interact with the platform they love.",
      paragraph:
        "In the Qno, we've simplified the entire process, so you can focus on the important things. Our modern design is built on the latest technologies, so you can spend more time learning and earning. We're dedicated to helping you reach your goals, so relax and let us take care of the rest.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-left"
                    data-reveal-container=".split-item"
                  >
                    Discover Projects
                  </h3>
                  <p
                    className="m-0 reveal-from-left"
                    data-reveal-container=".split-item"
                    data-reveal-delay="100"
                  >
                    Join us and discover amazing opportunities that are relevant
                    to your degree and career goals. Will find projects best
                    suited to you, or have a look at all of the choices
                    available.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-02 reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-01.png")}
                      alt="Features split top 01"
                      width={654}
                      height={522}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-right"
                    data-reveal-container=".split-item"
                  >
                    You Decide
                  </h3>
                  <p
                    className="m-0 reveal-from-right"
                    data-reveal-container=".split-item"
                    data-reveal-delay="100"
                  >
                    Found a project? We'll walk you through setting deadlines
                    and identify any limitations. Once you've submitted your
                    proposal, you'll receive a response within 2 business
                    days—or sooner.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-03 reveal-from-left",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-02.png")}
                      alt="Features split top 02"
                      width={654}
                      height={521}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-left"
                    data-reveal-container=".split-item"
                  >
                    Work with Professional
                  </h3>
                  <p
                    className="m-0 reveal-from-left"
                    data-reveal-container=".split-item"
                    data-reveal-delay="100"
                  >
                    Once your proposal has been approved, you'll set up a
                    meeting with your industry expert and start working on the
                    project. You'll be guided through the entire process of
                    building a real-world product, so you can get the most out
                    of the Qno experience.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-04 reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-03.png")}
                      alt="Features split top 03"
                      width={654}
                      height={436}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: "absolute",
  width: "118.18%",
  maxWidth: "118.18%",
  top: "-7.07%",
  left: "-9.09%",
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
